import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function ServicesHome({ bgImg, serviceName, serviceText }) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="w-full">
      <div className="max-w-5xl mx-auto justify-center">
        <div className=" block md:flex mx-auto justify-center bg-white border-gray-200 shadow-lg lg:pl-0 pr-6 pl-6 text-center md:text-start mb-7 rounded-3xl">
          <div className="md:w-[40%] w-full flex flex-col">
            <div
              className="w-full h-[90vh] md:h-full bg-cover bg-center rounded-tl-3xl rounded-bl-3xl"
              style={{ backgroundImage: `url("${bgImg}")` }}
            >
              <img
                src={rpdata?.dbPrincipal?.logo}
                alt="Not Found"
                className="relative w-[100px] bg-[#fff] rounded-br-2xl rounded-tl-3xl py-[10px]"
              />
            </div>
          </div>
          <div className="flex flex-col self-center py-4 px-8 md:w-[60%] w-full">
            <h5 className="text-[20px] leading-[24px] ">{serviceName}</h5>
            <p className="text-[15px] leading-[24px] pt-2">{serviceText}</p>
            <div className="flex justify-center lg:justify-start">
              <ButtonContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesHome;
